function Maintenance() {
  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f1f1f1',
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          padding: '40px',
          maxWidth: '600px',
          width: '100%',
        }}
      >
        <h1
          style={{
            color: '#f39c12',
            fontSize: '2.5em',
            marginBottom: '20px',
          }}
        >
          O sistema está fora do ar para atualização do ERP!
        </h1>
        <p
          style={{
            fontSize: '1.2em',
            color: '#555',
            marginBottom: '30px',
          }}
        >
          Em breve, assim que o ERP estabilizar, o sistema estará disponível novamente.
        </p>
        <p
          style={{
            fontSize: '1.2em',
            color: '#555',
            marginBottom: '30px',
          }}
        >
          Agradecemos pela compreensão!
        </p>
        <div
          style={{
            fontSize: '1em',
            color: '#888',
          }}
        >
          Qualquer dúvida, favor procurar a Controladoria (Adriana Borges ou Evanilson).
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
