import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

export interface ActionMenuOption {
  icon: React.ReactElement;
  label: string;
  actionCallback: () => void;
}

export interface CutomizedMenuOption {
  menu: React.ReactElement;
  label: string;
}

interface ActionsMenuPros {
  actionList: Array<ActionMenuOption | CutomizedMenuOption>;
}

export default function ActionsMenu(props: ActionsMenuPros) {
  const { actionList } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (actionCallback: () => void) => {
    setAnchorEl(null);
    actionCallback();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertOutlinedIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              ml: -4,
              '& .MuiAvatar-root': {
                width: 0,
                height: 0,
                ml: 0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 0,
                width: 18,
                height: 18,
                bgcolor: 'background.paper',
                transform: `translateY(${actionList.length * 100}%) translateX(20%) rotate(45deg)`,
                zIndex: 0,
              },
            },
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {actionList.map((actionOption: ActionMenuOption | CutomizedMenuOption) =>
          (actionOption as ActionMenuOption).icon ? (
            <MenuItem
              key={actionOption.label}
              onClick={() => handleAction((actionOption as ActionMenuOption).actionCallback)}
            >
              <ListItemIcon>{(actionOption as ActionMenuOption).icon}</ListItemIcon>
              {actionOption.label}
            </MenuItem>
          ) : (
            <div>{open && (actionOption as CutomizedMenuOption).menu}</div>
          ),
        )}
      </Menu>
    </div>
  );
}
